import React, { Component } from "react"
import { renderSVG } from "."
import CustomHeader from "../components/base/CustomHeader"
import Footer from "../components/base/Footer"
import SEO from "../components/base/seo"
import Hero from "../components/marketing/Hero"
import PrevProjects from "../components/marketing/PrevProjects"

export default class Portfolio extends Component {
  render() {
    return (
      <>
        <div>
          <SEO description="Blixify offer custom development to bring digital products into life, these are our portfolios ranging from agriculture, logistics to inventory" />
          <div className="relative bg-gray-50 overflow-hidden">
            <CustomHeader />
            {renderSVG()}
            <Hero title="Our Portfolio" />
          </div>
          <PrevProjects />
          <Footer />
        </div>
      </>
    )
  }
}
