import React, { Component } from "react"
import DownloadApple from "../../images/download_apple.png"
import DownloadAndroid from "../../images/download_google.png"
import PortfolioMeBooks from "../../images/portfolio_mebooks.webp"
import PortfolioMySukarela from "../../images/portfolio_mysukarela.png"
import PortfolioQCat from "../../images/portfolio_qcat.webp"
import PortfolioThinkCity from "../../images/portfolio_thinkcity.png"
import Button from "../base/Button"
import Icon, { IconType } from "../base/Icon"

interface Project {
  title: string
  picture: string
  description: string
  webLink?: string
  appleLink?: string
  androidLink?: string
  icon: IconType
  iconAttribute?: {
    viewBox: string
    inverted: boolean
  }
}

interface State {
  projects: Project[]
}

export default class Portfolio extends Component {
  state: State = {
    projects: [
      {
        title: "MySukarela",
        description:
          "MySukarela serves as a platform that connects volunteers with non-profit organizations (NGO) to conduct humanitarian tasks. Find volunteering projects nearby you and get started volunteering now! It works by allowing interested individuals to sign up and volunteer to combat a humanitarian cause. It has gathered 300,000 registered users & 10,000 deployed volunteers. It was orginally migration from MyVac (Gathering Volunteers for COVID-19 vaccination centers)",
        picture: PortfolioMySukarela,
        webLink: "https://mysukarela.com",
        icon: IconType.GROUP,
      },
      {
        title: "Me Books",
        description:
          "Me Books is a wonderland of children's books with built-in & customisable audio. It allows childrens to listen to narrations from world-reowned figures, enjoy the ability to record their own voice. This gives parents the chance to participate together with their children in an interactive learning environment guaranteed to spark their children's creativity",
        picture: PortfolioMeBooks,
        icon: IconType.STAR,
        appleLink: "https://apps.apple.com/us/app/id1510382298",
        androidLink:
          "https://play.google.com/store/apps/details?id=co.mebooks.plus",
      },
      {
        title: "Think City",
        description:
          "Think City is a strategic urban policy adviser, venture builder and project delivery partner, working in city rejuvenation efforts. Think City Web VR project is initiated to create awareness on rejuvenating UNESCO/ heritage sites in Johor Bahru. The project is installed at public bus stops with Leap Motion as navigation sensor, viewable on VR on Think City Ruang office. All user interactions are collected to help Think City to improve on how to improve their projects better.",
        picture: PortfolioThinkCity,
        icon: IconType.STAR,
        webLink: "https://thinkcitywebvr.web.app",
      },
      {
        title: "Q-CaT CPR",
        description:
          "Q-CaT CPR mobile app connects to Q-CaT CPR Kit via BLE(Bluetooth Low Energy). This allows medical trainees to measure & analyse the depth & rate of compression made. Medical trainees are allowed to start their CPR training with a certain duration intervals such as 2 minutes etc.",
        picture: PortfolioQCat,
        appleLink: "https://apps.apple.com/us/app/qcat-cpr/id1622528398",
        androidLink:
          "https://play.google.com/store/apps/details?id=my.com.montana.qcatcpr",
        icon: IconType.BLUETOOTH,
      },
    ],
  }

  handleWebLink = (url: string) => {
    window.open(url)
  }

  renderProjects = () => {
    const projectsView: any = []

    this.state.projects.map((eachProject, index) => {
      let defaultClassName = "relative"
      if (index !== 0) {
        defaultClassName = "relative mt-24"
      }

      const odd = index % 2 ? false : true
      const childComponent = (
        <div>
          <div>
            <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
              <Icon
                type={eachProject.icon}
                className="h-6 w-6 text-white"
                inverted={eachProject.iconAttribute?.inverted ?? false}
                viewBox={eachProject.iconAttribute?.viewBox ?? "0 0 20 20"}
              />
            </span>
          </div>
          <div className="mt-6">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              {eachProject.title}
            </h2>
            <p className="mt-4 text-lg text-gray-500">
              {eachProject.description}
            </p>
            {eachProject.webLink && (
              <div className="mt-6">
                <Button
                  text="Learn More"
                  type="normal"
                  onClick={this.handleWebLink.bind(this, eachProject.webLink)}
                />
              </div>
            )}
            {eachProject.appleLink && (
              <div className="mt-6">
                <img
                  className="h-11 cursor-pointer"
                  src={DownloadApple}
                  alt="Download App from IOS"
                  onClick={this.handleWebLink.bind(this, eachProject.appleLink)}
                />
              </div>
            )}
            {eachProject.androidLink && (
              <div className="mt-6 pointer">
                <img
                  className="h-11 cursor-pointer"
                  src={DownloadAndroid}
                  alt="Download App from Android"
                  onClick={this.handleWebLink.bind(
                    this,
                    eachProject.androidLink
                  )}
                />
              </div>
            )}
          </div>
        </div>
      )

      if (odd) {
        projectsView.push(
          <div className={defaultClassName} key={index}>
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
                {childComponent}
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0">
                <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={eachProject.picture}
                    alt="Inbox user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      } else {
        projectsView.push(
          <div className={defaultClassName} key={index}>
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
              <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
                {childComponent}
              </div>
              <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
                <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
                  <img
                    className="w-full rounded-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                    src={eachProject.picture}
                    alt="Customer profile user interface"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }

      return null
    })

    return projectsView
  }

  render() {
    return (
      <div id="work" className="relative bg-white pt-16 pb-32 overflow-hidden">
        {this.renderProjects()}
      </div>
    )
  }
}
